.landing-page {
    text-align: center;
    padding: 20px;
  }
  
  .landing-page h1 {
    font-family: 'Arial', sans-serif; /* Replace with your preferred font */
    font-size: 2.5em;
    color: #333;
  }
  
  .landing-page p {
    font-family: 'Arial', sans-serif; /* Replace with your preferred font */
    color: #666;
    margin-bottom: 15px; /* Add spacing between paragraphs */
    line-height: 1.5; /* Improve readability */
  }
  

  .landing-page {
    text-align: center;
    padding: 20px;
    font-family: 'Roboto', 'Arial', sans-serif;
  }
  
  .faq-section {
    margin-top: 20px;
  }
  